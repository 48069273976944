import React, { useState, useEffect } from "react";
import { BsCart, BsWallet } from "react-icons/bs";
import { BsFileBarGraph, BsArrowUp } from "react-icons/bs";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import Charts from "../../chart/Charts";
import "./DashComponent.css";
import { useTranslation } from "react-i18next";

const setStartEndDate = (filterBy) => {
  const today = new Date();
  let start, end;

  if (filterBy === "weekly") {
    start = new Date(today);
    start.setDate(today.getDate() - today.getDay() + 1);
    end = new Date(start);
    end.setDate(start.getDate() + 6);
  } else if (filterBy === "monthly") {
    start = new Date(today.getFullYear(), today.getMonth(), 1);
    end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  } else if (filterBy === "yearly") {
    start = new Date(today.getFullYear(), 0, 1);
    end = new Date(today.getFullYear(), 11, 31);
  } else if (filterBy === "yesterday") {
    start = new Date(today);
    start.setDate(today.getDate() - 1);
    end = start;
  } else {
    start = today;
    end = today;
  }

  return {
    startDate: formatStartDate(start, filterBy),
  };
};

const formatStartDate = (startDate, filterBy) => {
  const monthName = startDate.toLocaleString("default", { month: "short" });
  const year = startDate.getFullYear();
  if (filterBy === "yearly") {
    return `1 Jan ${year} to 31 Dec ${year}`;
  } else if (filterBy === "monthly") {
    const lastDayOfMonth = new Date(
      year,
      startDate.getMonth() + 1,
      0
    ).getDate();
    return `1 ${monthName} ${year} to ${lastDayOfMonth} ${monthName} ${year}`;
  } else if (filterBy === "weekly") {
    const endOfWeek = new Date(startDate);
    endOfWeek.setDate(startDate.getDate() + 6);
    const startMonth = startDate.toLocaleString("default", { month: "short" });
    const endMonth = endOfWeek.toLocaleString("default", { month: "short" });
    return `${startDate.getDate()} ${startMonth} ${year} to ${endOfWeek.getDate()} ${endMonth} ${year}`;
  } else {
    return startDate.toISOString().split("T")[0];
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const filterData = async (filterBy, filterOnly, payment_mode) => {
  try {
    let dashboardRequestUrl = `${BASE_URL}/v1/reports/fetch_dashboard_data?report_type=dashboard&filter_by=${filterBy}&filter_only=${filterOnly}&filter_type=${filterBy}&payment_mode=${payment_mode}`;
    const response = await sendRequest("GET", dashboardRequestUrl);
    return response.data;
  } catch (error) {}
};

const Expenses = ({ data }) => {
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState("today");
  const [expenseData, setExpenseData] = useState({});
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    if (isInitialLoading) {
      setExpenseData(data);
    }
  }, [data, expenseData]);

  const capitalizedFilterBy = capitalizeFirstLetter(t(filterBy));

  return (
    <div className="col-md-4">
      <div className="dash-box large-box">
        <div className="row-content">
          <div className="col-md-6">
            <h3>
              <BsWallet />
              {t("Expenses")}
            </h3>
            <div className="expanse-fetch-total">
              {expenseData?.total_expanse > 0
                ? expenseData?.total_expanse?.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                : "₹0.00"}
            </div>
          </div>
          <div style={{ flex: 3 }} className="col-md-6">
            <select
              className="form-select text-dark"
              aria-label="Default select example"
              value={filterBy}
              onChange={async (e) => {
                const value = e.target.value;
                setFilterBy(value);
                setIsInitialLoading(false);
                try {
                  const filteredData = await filterData(value, "expanse");
                  setExpenseData(filteredData);
                } catch (error) {
                  console.error("Error while fetching filtered data:", error);
                }
              }}
            >
              <option value="yesterday">{t("Yesterday")}</option>
              <option value="today">{t("Today")}</option>
              <option value="weekly">{t("Weekly")}</option>
              <option value="monthly">{t("Monthly")}</option>
              <option value="yearly">{t("Yearly")}</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="dash-report-txt">
            <h5>{`${t(capitalizedFilterBy)} ${t("Expenses")}`}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sale = ({ data }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(setStartEndDate("today"));
  const [saleData, setSaleData] = useState({});
  const [filterBy, setFilterBy] = useState("today");
  const [paymentMode, setPaymentMode] = useState("all");
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const capitalizedFilterBy = capitalizeFirstLetter(t(filterBy));
  useEffect(() => {
    if (isInitialLoading) {
      setSaleData(data);
    }
  }, [data, saleData, filterBy]);

  return (
    <>
      <div className="col-md-8">
        <div className="dash-box large-box">
          <div className="row">
            <div className="col-md-4">
              <h3>
                <BsFileBarGraph />
                {t("Sale")}
              </h3>
              <select
                className="form-select form-dashborad-main-box-filter text-dark"
                aria-label="Default select example"
                value={paymentMode}
                onChange={async (e) => {
                  const value = e.target.value;
                  setFilterBy(filterBy);
                  setStartDate(setStartEndDate(e.target.value));
                  setPaymentMode(value);
                  setIsInitialLoading(false);
                  try {
                    const filteredData = await filterData(
                      filterBy,
                      "sale",
                      value
                    );
                    setSaleData(filteredData);
                  } catch (error) {
                    console.error("Error while fetching filtered data:", error);
                  }
                }}
              >
                <option value="">{t("All")}</option>
                <option value={0}>{t("cash")}</option>
                <option value={1}>{t("online")}</option>
                <option value={2}>{t("card")}</option>
              </select>
              <div className="sale-details">
                <div className="dashvalue">
                  <h4 className="purchase-value-with-rs">
                    {saleData?.total_amount > 0.0
                      ? Number(
                          saleData?.total_amount?.toFixed(2)
                        ).toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                      : "₹0.00"}
                  </h4>
                </div>
                <h5>
                  {t("Total Sale")} {capitalizedFilterBy}
                </h5>
                <h4>
                  <BsArrowUp />
                  {saleData ? saleData?.growth : "00"}%
                </h4>
                <p>
                  {t("Growth")} {capitalizedFilterBy}
                </p>
              </div>
            </div>
            <div className="col-md-8">
              <select
                className="form-select form-dashborad-main-box-filter text-dark"
                aria-label="Default select example"
                value={filterBy}
                onChange={async (e) => {
                  const value = e.target.value;
                  setFilterBy(value);
                  setStartDate(setStartEndDate(e.target.value));
                  setIsInitialLoading(false);
                  try {
                    const filteredData = await filterData(
                      value,
                      "sale",
                      paymentMode
                    );
                    setSaleData(filteredData);
                  } catch (error) {
                    console.error("Error while fetching filtered data:", error);
                  }
                }}
              >
                <option value={t("yesterday")}>{t("Yesterday")}</option>
                <option value={t("today")}>{t("Today")}</option>
                <option value={t("weekly")}>{t("Weekly")}</option>
                <option value={t("monthly")}>{t("Monthly")}</option>
                <option value={t("yearly")}>{t("Yearly")}</option>
              </select>
              <div className="dash-report-txt">
                <Charts chartData={saleData?.chart_data} />
                <h5>
                  {t("Report: from")} {startDate.startDate}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const YouRecieve = ({ data }) => {
  const { t } = useTranslation();
  const [youReceive, setYouReceive] = useState({});

  useEffect(() => {
    setYouReceive(data);
  }, [data, youReceive]);
  return (
    <>
      <div className="col-md-4 yourecieve">
        <div className="card-smal dash-box">
          <h3>
            <BsArrowUp /> {t("You’ll Recieve")}
          </h3>
          <div className="col-md-12">
            {youReceive?.you_will_receive > 0 ? (
              <>
                <div className="dash-receive-value">
                  <h3 className="receive-value-with-rs">
                    {youReceive?.you_will_receive?.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </h3>
                </div>
                <p>{t("Amount to recieve")}</p>
              </>
            ) : (
              <div className="dash-receive-value">
                <h3 className="receive-value-with-rs">{"0.00"}</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Youpay = ({ data }) => {
  const { t } = useTranslation();
  const [youpay, setYoupay] = useState({});

  useEffect(() => {
    setYoupay(data);
  }, [data]);

  return (
    <>
      <div className="col-md-4 youpay">
        <div className="card-smal dash-box">
          <h3>
            <BsArrowUp /> {t("You’ll Pay")}
          </h3>
          <div className="col-md-12">
            {youpay ? (
              <>
                <div className="dash-pay-value">
                  <h3 className="pay-value-with-rs">
                    {youpay?.you_will_pay?.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </h3>
                </div>
              </>
            ) : (
              <div className="dash-pay-value">
                <h3 className="pay-value-with-rs">
                  {youpay?.you_will_pay?.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </h3>
                <p>{t("No pending dues")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DashPurchase = ({ data }) => {
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState("today");
  const [purchaseData, setPurchaseData] = useState({});
  const [isInitialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (isInitialLoading) {
      setPurchaseData(data);
    }
  }, [data, purchaseData, filterBy]);

  return (
    <>
      <div className="col-md-4 dashpurchase">
        <div className="card-smal dash-box">
          <div className="row-content">
            <div className="col-md-6">
              <h3>
                <BsCart />
                {t("Purchase")}
              </h3>
            </div>
            <div style={{ flex: 3 }} className="col-md-6">
              <select
                className="form-select text-dark"
                aria-label="Default select example"
                value={filterBy}
                onChange={async (e) => {
                  const value = e.target.value;
                  setFilterBy(value);
                  setInitialLoading(false);
                  try {
                    const filteredData = await filterData(value, "purchase");
                    setPurchaseData(filteredData);
                  } catch (error) {
                    console.error("Error while fetching filtered data:", error);
                  }
                }}
              >
                <option value={t("yesterday")}>{t("Yesterday")}</option>
                <option value={t("today")}>{t("Today")}</option>
                <option value={t("weekly")}>{t("Weekly")}</option>
                <option value={t("monthly")}>{t("Monthly")}</option>
                <option value={t("yearly")}>{t("Yearly")}</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {purchaseData?.purchase_amount > 0.0 ? (
                <>
                  <div className="dash-purchase-value">
                    <h3 className="purchase-value-with-rs">
                      {purchaseData?.purchase_amount?.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </h3>
                  </div>
                </>
              ) : (
                <div className="dash-purchase-value">
                  <h3 className="purchase-value-with-rs">₹{"0.00"}</h3>
                  <p>
                    {t("You have no purchased")}
                    <br />
                    {t("items entered for selected time")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Expenses, Sale, YouRecieve, Youpay, DashPurchase };
