import React, { useState } from "react";
import "./PurchaseReceipt.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseReceipt from "./NewPurchaseReceipt";

function PurchaseReceipt() {
  const[openNewPurchaseReceipt, setNewPurchaseReceipt] = useState();

  const handleNewPurchaseReceipt = () => {
    setNewPurchaseReceipt(true);
  }

  const closeNewPurchaseReceipt = () => {
    setNewPurchaseReceipt(false);
  }
 
  return (
    <>
      <div className="purchase-receipt-main-container">
        <div className="purchase-receipt-container-first-div">
          <div className="purchase-receipt-first-left">
            <span className="all-purchase-receipt-show">All Receipt()</span>
          </div>
          <div className="purchase-receipt-first-right">
            <button
              className="purchase-receipt-first-right-botton"
              onClick={handleNewPurchaseReceipt}
            >
              New Receipt
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-receipt-table-group">
          <table className="purchase-receipt-table">
            <thead>
              <tr className="purchase-receipt-thead-row">
                <th className="purchase-receipt-tab-th">Date</th>
                <th className="purchase-receipt-tab-th">Receipt No</th>
                <th className="purchase-receipt-tab-th">Contact</th>
                <th className="purchase-receipt-tab-th">Supplier Delivery Challan No</th>
                <th className="purchase-receipt-tab-th">Supplier Delivery Challan Date</th>
                <th className="purchase-receipt-tab-th">Ref.No</th>
                <th className="purchase-receipt-tab-th">Due Days</th>
                <th className="purchase-receipt-tab-th">Status</th>
                <th className="purchase-receipt-tab-th">Amount</th>
                <th className="purchase-receipt-tab-th">Action</th>              
              </tr>
            </thead>
            <tbody>
                <tr>
                  <td className="purchase-receipt-tab-td"></td>
                  <td className="purchase-receipt-tab-td"></td>
                  <td className="purchase-receipt-tab-td"></td>
                  <td className="purchase-receipt-tab-td"></td>
                  <td className="purchase-receipt-tab-td"></td>
                  <td className="purchase-receipt-tab-td"></td>       
                  <td className="purchase-receipt-tab-td"></td>
                  <td className="purchase-receipt-tab-td"></td>
                  <td className="purchase-receipt-tab-td"></td>
                  <td className="purchase-receipt-tab-td">
                    <FontAwesomeIcon icon={faCaretDown} className="purchase-receipt-table-body-td"/>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>

      {openNewPurchaseReceipt && (
        <div className="new-purchase-receipt-popup-container">
          <NewPurchaseReceipt onClose={closeNewPurchaseReceipt}/>
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseReceipt;
