import React, { useState } from "react";
import "./NewPurchaseBill.css"
import SelectGuest from "../../Restaurant/SelectGuest/SelectGuest";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import CommonSalePopupTable from "../../CompanySales/CommonSalePopupTable/CommonSalePopupTable";
import SaleCommonPopup from "../../CompanySales/SaleCommonPopupHeader/SaleCommonPopupHeader";

function NewPurchaseBill({ onClose }) {

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [isShowCustomerPopup, setShowCustomerPopup] =useState(false);

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers/customer_search?query=${term}`
        );
        const customers = response.data;
        if (customers.length > 0) {
          setFilteredCustomers(customers);
          setShowDropdown(true);
          setShowAddCustomer(false);
        } else {
          setFilteredCustomers([]);
          setShowDropdown(true);
          setShowAddCustomer(true); 
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddCustomer(true); 
      }
    } else {
      setShowDropdown(false);
      setShowAddCustomer(false);
    }
  };

  const handleSelectCustomer = (customer) => {
    setSearchTerm(customer.first_name || customer.last_name);
    setShowDropdown(false);
  };

  const handleCreateCustomer = () => {
    setShowCustomerPopup(true);
  }

  const handleAddCustomer = () => {
    setShowCustomerPopup(true);
    setShowDropdown(false);   
    setShowAddCustomer(false);
  };

  return (
    <>
      <div className="new-purchase-bill-main-container">
        <SaleCommonPopup onClose={onClose} />
        <div className="new-purchase-bill-sub-container">
          <div className="new-purchase-bill-first-div">
            <div className="new-purchase-bill-estimate-no-grp">
              <span className="new-purchase-bill-est-no-title">Purchase Bill No</span>
              <span className="new-purchase-bill-est-no">5</span>
            </div>
            <div className="new-purchase-bill-estimate-date-grp">
              <span className="new-purchase-bill-estdate-title">Bill Date</span>
              <span className="new-purchase-bill-estdate-input">
                <input className="form-control new-purchase-bill-first-div-input" type="date" />
              </span>
            </div>
            <div className="new-purchase-bill-tilldate-grp">
              <span className="new-purchase-bill-est-tilldate-title">Due Date</span>
              <span className="new-purchase-bill-est-tilldate-input">
                <input className="form-control new-purchase-bill-first-div-input" type="date" />
              </span>
            </div>
            <div className="new-purchase-bill-reference-grp">
              <span className="new-purchase-bill-est-reference-title">Purchase Order #</span>
              <span className="new-purchase-bill-est-reference-input">
                <input className="form-control new-purchase-bill-first-div-input" />
              </span>
            </div>
            <div className="new-purchase-bill-reference-grp">
              <span className="new-purchase-bill-est-reference-title">Type</span>
              <span className="new-purchase-bill-est-reference-input">
                <select>
                  <option selected>Regular</option>
                  <option>Import with Tax Payment</option>
                  <option>Import without Tax Payment</option>
                  <option>Purchase From SEZ with Tax Payment</option>
                </select>
              </span>
            </div>
          </div>

          <div className="new-purchase-bill-second-div">
            <div className="new-purchase-bill-customer-div">
              <span className="new-purchase-bill-cust-title">Supplier/Vender</span>
              <span className="new-purchase-bill-cust-search">
                <input
                  className="form-control new-purchase-bill-cust-field"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search customer..."
                />
                {showDropdown && (
                  <ul className="new-purchase-bill-customer-dropdown">
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers.map((customer, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectCustomer(customer)}
                          className="new-purchase-bill-dropdown-item"
                        >
                          {customer.name||customer.first_name || customer.last_name} {/* Assuming customer object has a name field */}
                        </li>
                      ))
                    ) : (
                      <li className="new-purchase-bill-cust-dropdown-item">No customer found</li>
                    )}
                    {showAddCustomer && (
                      <li className="customer-dropdown-item" onClick={handleAddCustomer}>
                        <button className="btn btn-primary" onClick={handleCreateCustomer}>Add Customer</button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
            </div>

            <div className="new-purchase-bill-buyer-order-grp">
              <span className="new-purchase-bill-buyer-order-title">Supplier Invoice No</span>
              <input className="form-control new-purchase-bill-buyer-order" />
            </div>
            <div className="new-purchase-bill-buyer-order-date-grp">
              <span className="new-purchase-bill-buyer-order-date-title">Supplier Invoice Date</span>
              <input className="form-control new-purchase-bill-buyer-order-date" type="date" />
            </div>
          </div>

          <div className="new-purchase-bill-table">
            <CommonSalePopupTable />
          </div>
        </div>
      </div>

      {isShowCustomerPopup && (
        <div className="new-purchase-bill-customer-popup">
          <div className="new-purchase-bill-sub-customer-container">
            <SelectGuest onClose={onClose}/>
          </div>
        </div>
      )}
    </>
  );
}

export default NewPurchaseBill;
