import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, SUCCESS_MESSAGE } from "../../Url/constants";
import Search from "../Search/Search";
import CustomTable from "../Common/CustomTable";
import EntriesPerPageDropdown from "../Common/EntriesPerPageDropdown";
import ErrorNotification from "../../Notification/ErrorNotification";
import PaginationControls from "../Common/PaginationControls";
import "./billingTransaction.css";
import { BsCloudDownload, BsPencilSquare } from "react-icons/bs";
import { sendRequest } from "../../ApiRequest";
import successNotification from "../../Notification/SuccessNotification";
import UpdateStatusPopup from "./UpdateBillingStatus";
import { useTranslation } from "react-i18next";
import Modal from '../../ModalPermission.js'

const RecentTransiction = ({
  updateTransactions,
  setUpdateTransactions,
  billSettings,
}) => {
  const [RecentTransaction, setRecentTransaction] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updatePopup, setUpdatePopUp] = useState(false);
  const [isPast, setIsPast] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);
  const [currentTab, setCurrentTab] = useState("sales");
  const currentYear = new Date().getFullYear();
  const [todaySales, setTodaysSales] = useState(0);
  const [previousSales, setPreviousSales] = useState(0);
  const [todaysBillCount, setTodaysBillCount] = useState(0);
  const [previousBillCount, setPreviousBillCount] = useState(0);
  const [errorUnauthorize, setErrorUnauthorize] = useState(false);
  const [searchByStatus, setSerachByStatus] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    fetchTransactions();
    setUpdateTransactions(false);
    if (updateTransactions) {
      setCurrentTab("sales");
    }
  }, [isPast, updateTransactions, recordPerPage, searchQuery, searchByStatus, currentTab]);

  useEffect(() => {
    setIsPast(false);
  }, [updateTransactions]);

  const isCheckboxEnabled = (optionKey) => {
    const option = billSettings.find((setting) => setting.name === optionKey);
    return option && option.billing_setting_config
      ? option.billing_setting_config.status
      : false;
  };

  const paymentModeEnabled = isCheckboxEnabled("payment_mode");
  const downloadEnabled = isCheckboxEnabled("download_pdf");

  const fetchTransactions = async (pageNumber = 1) => {
    try {
      let request_url = `${BASE_URL}/v1/reports/${currentTab}?page=${pageNumber}&per_page=${recordPerPage}&redirect_to=billings`;
      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }
      if (isPast) {
        request_url += `&is_past=${true}`;
      }
      if (currentTab === "purchase") {
        request_url += `&filter_by=${"yearly"}`;
        request_url += `&year=${currentYear}`;
      }
      if (searchByStatus) {
        request_url += `&status=${searchByStatus}`;
      }
      const response = await sendRequest("GET", request_url, null);
      if (response.status === 200) {
        const res = await response.data;
        setRecentTransaction(res.data);
        setTotalPages(res.total_pages);
        setTotalRecords(res.total_records);
        setTodaysSales(res.todays_sales_total);
        setPreviousSales(res.previous_sale_total);
        setTodaysBillCount(res.today_sales_count);
        setPreviousBillCount(res.previous_sales_count);
      }
    } catch (error) {
      if (error && error.data && error.data.errors) {
        if (error.data.errors.length === 49) {
          setErrorUnauthorize(true); // Handle unauthorized case
        } else {
          error.data.errors.forEach((err) => ErrorNotification(err)); // Handle other errors
        }
      } else {
        ErrorNotification('Unknown error occurred', error); // In case the error structure is different
      }
    }
  };

  const handleCloseItemModel = () => {
    setErrorUnauthorize(false);
    navigate("/dashboard");
  }

  const downloadBillingTemplate = async (id) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/billings/bills/${id}/download_billing_template_pdf`,
        null,
        { responseType: "blob" }
      );
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        successNotification(SUCCESS_MESSAGE("downloaded", "bill"));
      }
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  };

  // const fetchTotalSales = async () => {
  //   try {
  //     const params = isPast ? { is_past: true } : {};
  //     const queryString = new URLSearchParams(params).toString();
  //     const requestUrl = `${BASE_URL}/v1/billings/daily_total_sales?${queryString}`;
  //     const response = await sendRequest('GET', requestUrl, null)
  //     if (response.status === 200) {
  //       setTotalSales(response.data);
  //     }
  //   } catch (error) {
  //     error.data.errors.map(error => ErrorNotification(error));
  //   }
  // };

  const handleSearch = (e) => {
    if (e.length >= 2) {
      setSearchQuery(e);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      await fetchTransactions(pageNumber);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setRecordPerPage(perPage);
    setCurrentPage(1);
  };

  const updateStatus = async (newStatus) => {
    try {
      const params =
        currentTab === "purchase" ? { is_purchase: true } : { is_sale: true };
      params.status = newStatus;
      const queryString = new URLSearchParams(params).toString();
      const requestUrl = `${BASE_URL}/v1/billings/bills/${selectedTransaction}/update_billing_status?${queryString}`;
      const response = await sendRequest("PUT", requestUrl, null);
      if (response.status === 200) {
        successNotification(SUCCESS_MESSAGE("updated", "status"));
        setUpdatePopUp(false);
        fetchTransactions(currentPage);
      }
    } catch (error) {
      ErrorNotification("Something went wrong");
    }
  };

  const handleShowUpdatePopUp = (transaction) => {
    setSelectedTransaction(transaction);
    setUpdatePopUp(true);
  };

  const cancelUpdate = () => {
    setUpdatePopUp(false);
  };

  const handleStatusChange = (e) => {
    setSerachByStatus(e);
  };

  return (
    <>
      <div className="content billing-create-tab">
        <div className="row bottom_space">
          <div className="col-md-8 trans-new-record">
            <button
              className={`title-title-transations ${
                currentTab === "sales" && !isPast ? "active" : ""
              }`}
              onClick={() => {
                setCurrentTab("sales");
                setIsPast(false);
              }}
            >
              {t("Recent Transactions")} {`(${todaysBillCount})`}
            </button>
            <button
              className={`title-title-transations ${
                currentTab === "sales" && isPast ? "active" : ""
              }`}
              onClick={() => {
                setCurrentTab("sales");
                setIsPast(true);
              }}
            >
              {t("Past Transactions")} {`(${previousBillCount})`}
            </button>
          </div>
          <div className="col-md-4 text-right" style={{ width: 345 }}>
            <div className="sp-blue common-heigth-report-amount">
              <div className="sp-icon">
                <img
                  src={require("../../Images/rupees.png")}
                  alt="Rupees Icon"
                />
              </div>
              <div className="bill-amount-content">
                {!isPast && currentTab === "sales" && (
                  <>
                    <span>{t("Today's Sale")}</span>
                    <h3>{todaySales ? todaySales?.toFixed(2) : 0}</h3>
                  </>
                )}
                {isPast && currentTab === "sales" && (
                  <>
                    <span>{t("Past Sale")}</span>
                    <h3>{previousSales ? previousSales?.toFixed(2) : 0}</h3>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pagination-dropdown">
          <div className="pagination-controls billing-marigin-bottom">
            <EntriesPerPageDropdown
              recordPerPage={recordPerPage}
              handleRecordPerPageChange={handleRecordPerPageChange}
            />
          </div>
          <div className="billing-search-container">
            <div className="search-bill-by-status">
              <select
                onChange={(e) => handleStatusChange(e.target.value)}
                className="form-select"
                defaultValue=""
              >
                <option value="" disabled>{t("Select Status")}</option>
                <option value={1}>{t("Success")}</option>
                <option value={3}>{t("Pending")}</option>
                <option value={4}>{t("Cancel")}</option>
              </select>
            </div>
            <div
              className="bill-search-attribute"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            >
              <Search placeholder={"Search bill ..."} />
            </div>
          </div>
        </div>
        <div className="table-view suppliers-table trans-common-table">
          <CustomTable
            col_names={[
              "#",
              "Invoice No",
              "Customer Details",
              "Amount",
              "Profit",
              "Profit(%)",
              paymentModeEnabled ? "Payment Mode" : "",
              "Status",
              "Detailed View",
              downloadEnabled && currentTab === "sales" ? "Download" : "",
            ].filter(Boolean)}
            data={RecentTransaction.map((transaction, index) => {
              const rowData = {
                "#": startIndex + index,
                "Invoice No": transaction.bill_number,
                Item: transaction.item_id,
                "Customer Details": transaction.email
                  ? transaction.email
                  : transaction.phone,
                Amount: `₹ ${transaction.total_amount.toFixed(2)}`,
                Profit: (
                  <span
                    style={{
                      color:
                        transaction.total_purchase_amount &&
                        transaction.total_purchase_amount >
                          transaction.total_amount
                          ? "red"
                          : "inherit",
                    }}
                  >
                    {transaction.total_purchase_amount &&
                    transaction.total_purchase_amount > transaction.total_amount ? (
                      <i
                        className="fas fa-arrow-down"
                        style={{ marginRight: 5 }}
                      />
                    ) : (
                      <i
                        className="fas fa-arrow-up"
                        style={{ marginRight: 5 }}
                      />
                    )}
                    ₹{" "}
                    {transaction.total_purchase_amount && transaction.total_purchase_amount > 0
                      ? transaction.total_purchase_amount > transaction.total_amount
                        ? "0.00"
                        : (transaction.total_amount - transaction.total_purchase_amount).toFixed(2)
                      : "0.00"}
                  </span>
                ),
                "Profit(%)":
                  transaction.total_purchase_amount &&
                  transaction.total_purchase_amount > 0
                    ? transaction.total_purchase_amount > transaction.total_amount
                      ? "0.00"
                      : `${(
                          ((transaction.total_amount - transaction.total_purchase_amount).toFixed(2) * 100) /
                          transaction.total_purchase_amount
                        ).toFixed(2)}%`
                    : "0.00",
                  Status: (
                    <div>
                      <span className="qty-item-value">{transaction.status}</span>
                      {transaction.status !== "cancel" && (
                        <span className="status-edit-icon">
                          <BsPencilSquare
                            onClick={() => handleShowUpdatePopUp(transaction.id)}
                          />
                        </span>
                      )}
                    </div>
                  ),
                "Detailed View": (
                  <td>
                    <Link to={`/invoice/${transaction.id}`} target="_blank">
                      {t("View Invoice")}
                    </Link>
                  </td>
                ),
                // 'Download': <div className="col-md-2 text-right" onClick={() => downloadBillingTemplate(transaction.id)}>
                //   <BsCloudDownload style={{ cursor: 'pointer' }} />
                // </div>
              };
              if (paymentModeEnabled) {
                rowData["Payment Mode"] = transaction.payment_mode;
              }
              if (downloadEnabled && currentTab === "sales") {
                rowData["Download"] = (
                  <div
                    className="col-md-2 text-right"
                    onClick={() => downloadBillingTemplate(transaction.id)}
                  >
                    <BsCloudDownload style={{ cursor: "pointer" }} />
                  </div>
                );
              }
              return rowData;
            })}
          />
          {RecentTransaction.length === 0 && searchQuery.length > 2 && (
            <div className="no-report-data-message">No match found</div>
          )}
          {RecentTransaction.length === 0 && searchQuery.length === 0 && (
            <div className="no-report-data-message">
              {t("No records found")}
            </div>
          )}
        </div>

        {totalRecords >= recordPerPage && (
          <div>
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          </div>
        )}
        {updatePopup && (
          <UpdateStatusPopup
            currentStatus={selectedTransaction.status}
            onClose={cancelUpdate}
            onUpdate={updateStatus}
          />
        )}
      </div>
      {
        errorUnauthorize && (
          <div className="item-unauthorize-modal-popup">
            <div className="">
              <Modal onClose={handleCloseItemModel}/>
            </div>
          </div>
        )
      }
    </>
  );
};

export default RecentTransiction;
