import React, { useState, useEffect } from "react";
import TaxBill from "../../components/TaxBilling/TaxBill";
import SendVia from "../../components/TaxBilling/SendVia";
import ItemSetting from "../../components/TaxBilling/ItemSetting";
import Copyrights from "../../components/Copyrights";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";

function TaxBilling() {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await sendRequest('get', `${BASE_URL}/v1/setting/settings/billing_settings`);
        setSettings(response.data.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  return (
    <>
      <div className="content row mb-0">
        <div className="col">
          <TaxBill settings={settings.filter(setting => setting.option_type === "tax_billing")} setSettings={setSettings} />
        </div>
        <div className="col">
          <SendVia settings={settings.filter(setting => setting.option_type === "send_via")} setSettings={setSettings} />
        </div>
        <div className="col">
          <ItemSetting settings={settings.filter(setting => setting.option_type === "feature_on_item")} setSettings={setSettings} />
        </div>
      </div>
      <Copyrights />
    </>
  );
}

export default TaxBilling;
