import React from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const CustomTable = ({ col_names, data, onSort, sortConfig }) => {
  const renderSortIcon = (columnName) => {
    if (!sortConfig || columnName !== sortConfig.key) {
      return <FaSort />;
    }
    return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const getCellValue = (row, col) => {
    if (typeof col === 'object') {
      return row[col.name];
    } else if (typeof col === 'string') {
      return row[col];
    }
    return '';
  };

  return (
    <table className="table table-striped">
      <thead className='sticky-header'>
        <tr>
          {col_names.map((col, index) => (
            <th key={index} scope="col">
              {typeof col === 'object' && col.sortable ? (
                <div onClick={() => onSort(col.name)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                  {col.display || col.name} 
                  <span style={{ marginLeft: '5px' }}>{renderSortIcon(col.name)}</span>
                </div>
              ) : (
                col
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((r, rowIndex) => (
          <tr key={rowIndex}>
            {col_names.map((c, colIndex) => (
              <td key={colIndex}>
                {c === 'File' && r[c] ? (
                  <a href={r[c]} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img
                      src={r[c]}
                      alt="Transaction File"
                      style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/100";
                      }}
                    />
                  </a>
                ) : (
                  r[c]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;