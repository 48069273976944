import React, { useState } from "react";
import "./PurchaseCreditNote.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseCreditNote from "./NewPurchaseCreditNote";

function PurchaseCreditNote() {
  const[openNewPurchaseCredit, setNewPurchaseCredit] = useState();

  const handleNewCreditNote = () => {
    setNewPurchaseCredit(true);
  }

  const closeNewPurchaseCredit = () => {
    setNewPurchaseCredit(false);
  }
 
  return (
    <>
      <div className="purchase-credit-note-main-container">
        <div className="purchase-credit-note-container-first-div">
          <div className="purchase-credit-note-first-left">
            <span className="all-purchase-credit-note-show">All Credit Note()</span>
          </div>
          <div className="purchase-credit-note-first-right">
            <button
              className="purchase-credit-note-first-right-botton"
              onClick={handleNewCreditNote}
            >
              New Credit Note
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-credit-note-table-group">
          <table className="purchase-credit-note-table">
            <thead>
              <tr className="purchase-credit-note-thead-row">
                <th className="purchase-credit-note-tab-th">Date</th>
                <th className="purchase-credit-note-tab-th">Trans No</th>
                <th className="purchase-credit-note-tab-th">Contact</th>
                <th className="purchase-credit-note-tab-th">Supplier Credit Note No</th>
                <th className="purchase-credit-note-tab-th">Supplier Credit Note Date</th>
                <th className="purchase-credit-note-tab-th">Ref.No</th>
                <th className="purchase-credit-note-tab-th">Amount</th>
                <th className="purchase-credit-note-tab-th">Due Amount</th>
                <th className="purchase-credit-note-tab-th">Action</th>              
              </tr>
            </thead>
            <tbody>
                <tr>
                  <td className="purchase-credit-note-tab-td"></td>
                  <td className="purchase-credit-note-tab-td"></td>
                  <td className="purchase-credit-note-tab-td"></td>
                  <td className="purchase-credit-note-tab-td"></td>
                  <td className="purchase-credit-note-tab-td"></td>
                  <td className="purchase-credit-note-tab-td"></td>       
                  <td className="purchase-credit-note-tab-td"></td>
                  <td className="purchase-credit-note-tab-td"></td>
                    <td className="purchase-credit-note-tab-td">
                      <FontAwesomeIcon icon={faCaretDown} className="purchase-credit-note-table-body-td"/>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>

      {openNewPurchaseCredit && (
        <div className="new-purchase-credit-note-popup-container">
          <NewPurchaseCreditNote onClose={closeNewPurchaseCredit}/>
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseCreditNote;
