import React, { useState, useEffect } from "react";
import "./ManagingTax.css"
import { FaInfoCircle } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import TaxGroup from "./TaxGroup";
import TaxRates from "./TaxRate";
import { getToken } from "../SecureStorage/Token";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import Header from "../Header";
import ErrorNotification from "../../Notification/ErrorNotification";
import successNotification from "../../Notification/SuccessNotification";
import { Tooltip } from "react-tooltip";
import Copyrights from "../Copyrights";
import { useTranslation } from "react-i18next";

const GSTSettings = () => {
  const token = getToken();
  const [enableGST, setEnableGST] = useState(false);
  // const [enableTcs, setEnableTCS] = useState(false);
  const [taxes, setTaxes] = useState([]);
  // const [show, setShow] = useState(false);
  const { t} = useTranslation();

  const handleGSTChange = async () => {
    const newStatus = !enableGST;
    setEnableGST(newStatus);

    const url = `${BASE_URL}/v1/setting/settings/update_gst_setting`;
    const data = {
      name: "GST",
      setting: {
        status: newStatus
      }
    };

    try {
      const response = await sendRequest('patch', url, data, token);
      if (response.status === 200) {
        const successMessage = newStatus ? 'GST enabled successfully!' : 'GST disabled successfully!';
        successNotification(successMessage);
      } else {
        ErrorNotification('Error updating GST setting!');
      }
    } catch (error) {
      ErrorNotification('Error updating GST setting!');
    }
  };

  // const handleTCSChange = () => {
  //   setEnableTCS(!enableTCS);
  // };

  useEffect(() => {

    const fetchGSTSetting = async () => {
      const url = `${BASE_URL}/v1/setting/settings/get_setting_status?name=GST`;
      try {
        const response = await sendRequest('get', url, null, token);
        if (response.status === 200) {
          const gstSetting = response.data;
          if (gstSetting) {
            setEnableGST(gstSetting.status);
          }
        } else {
          console.log('Error fetching GST setting!');
        }
      } catch (error) {
        console.error("Error fetching GST setting!", error);
      }
    };

    const fetchTaxRates = async () => {
      const url = `${BASE_URL}/v1/taxes/taxes`;
      try {
        const response = await sendRequest('get', url, null, token);
        if (response.status === 200 || response.data.length === 0) {
          const data = response.data;
          setTaxes(data.data);
        } else {
          ErrorNotification('Error fetching tax rates!');
        }
      } catch (error) {
        ErrorNotification('Error fetching tax rates!');
      }
    };

    fetchGSTSetting();
    fetchTaxRates();
  }, []);

  return (
    <>
      <Tooltip id="my-tooltip" />
      {/* <div className="gst-settings-container">
        <div className="row">
          <div className="col-4">
            <h5 className="taxrate-sticky1-header">GST Settings</h5>
            <hr className="hr-short" />
            <div className="gst-setting">
              <input
                type="checkbox"
                id="enable-gst"
                checked={enableGST}
                onChange={handleGSTChange}
              // className="enable-gst-setting"
              />
              <label htmlFor="enable-gst">
                Enable GST
              </label>
              <FaInfoCircle className="info-circle" />
            </div>
            <br /> */}
      {/* <div className="gst-setting">
            <input
              type="checkbox"
              id="enable-tcs"
              checked={enableTCS}
              onChange={handleTCSChange}
              style={{ transform: "scale(1.4)" }}
            />
            <label htmlFor="enable-tcs" className="enable-tcs">
              Enable TCS
            </label>
            <FaInfoCircle className="info-circle" />
            <span className="tcs-icon">
              <AiFillCrown className="fill-crown" />
            </span>
          </div> */}
      <br />
      {/* <button className="tax-list-btn">
            Tax List &gt;<span className="arrow-icon"></span>
          </button> */}

      {/* <p className="gst-filing">
            <br />
            <i className="fas fa-file-alt" ></i>
            <FaFileAlt />
            Get GST Filing at exclusive prices!
          </p> */}
      {/* </div> */}

      <div className="gst-settings-container">
        <div className="gst-box">
          {/* First Box */}
          <div className="box">
            <h5 className="gst-tax-header">{t("GST Settings")}</h5>
            <hr className="hr-short" />
            <div className="gst-setting">
              <input
                type="checkbox"
                id="enable-gst"
                checked={enableGST}
                onChange={handleGSTChange}
              // className="enable-gst-setting"
              />
              <label htmlFor="enable-gst">
                {t("Enable GST")}
              </label>
              <FaInfoCircle
                className="info-circle"
                data-tooltip-id="info-tooltip"
                data-tooltip-content="Enabling this option will apply an 18% GST on item, sales and billing pages."
                style={{ marginLeft: '10px' }}
              />
              <Tooltip id="info-tooltip" />
              {/* <FaInfoCircle className="info-circle" /> */}
            </div>

          </div>
          {/* Second Box */}

          <div className="box">
            <TaxRates taxes={taxes} setTaxes={setTaxes} />
          </div>

          {/* Third Box */}
          <div className="box">
            <TaxGroup taxes={taxes} setTaxes={setTaxes} />
          </div>
        </div>
      </div>
      <Copyrights />
    </>
  );
};

export default GSTSettings;
