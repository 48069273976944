import React, { useState, useEffect, useRef } from "react";
import "./BillingDropdown.css";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import { useTranslation } from "react-i18next";
import { sendRequest } from "../../ApiRequest";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const SearchableDropdown = ({ label, id, handleChange, autoFocus }) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [SearchedValue, setSearchedValue] = useState("");
  const inputRef = useRef(null);
  const { t } = useTranslation();

   useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [autoFocus]);

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setQuery(value);

    if (value.length >= 2) {
      searchItems(value);
      setSearchedValue(value);
    } else {
      setOptions([]);
      setIsOpen(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const searchItems = async (query) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/items?query=${query}&is_normal_billing=true`
      );
      const items = response.data.data;
      setOptions(items);
      setIsOpen(true);

      if (items.length === 1) {
        selectOption(items[0]);
        
      }
    } catch (error) {
      ErrorNotification(error.message);
    }
  };

  const selectOption = (option) => {
    if (option.quantity < 1) {
      ErrorNotification(
        `${option.item_name} - ${option.item_code} is out of stock.`
      );
    } else {
      let selected = false;
      if (option?.identifiers?.length > 0) {
        option.identifiers.forEach((identifier) => {
          if (query && identifier.identifier === SearchedValue && identifier.item_id === option.id) {
            handleChange(Object.assign({}, option, { query: SearchedValue }));
            selected = true;
          }
        });
      }
      if (!selected) {
        handleChange(option);
      }
      setQuery("");
      setIsOpen(false);
    }
  };
  

  return (
    <div className="searchable-dropdown">
      <BarcodeScannerComponent
        width={0}
        height={0}
        onUpdate={(err, result) => {
          if (result) {
            setQuery(result.text);
            searchItems(result.text);
          }
        }}
      />
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={t("Search by Items Name, Items Code, or Bar Code")}
        className="form-control"
        ref={inputRef}
      />
      {isOpen && options.length > 1 && (
        <div
          className="dropdown-container"
          style={{ maxHeight: "100px", overflowY: "auto" }}
        >
          {options.map((option) => (
            <div
              key={option.id}
              className="dropdown-option"
              onClick={() => selectOption(option)}
            >
              {option.item_name} - {option.item_code} ({option.bar_code})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
