import React, { useState } from "react";
import "./BillPayment.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewBillPayment from "./NewBillPayment";

function BillPayment() {
  const [openNewBillPayment, setNewBillPayment] = useState(false);

  const handlNewBillPay = () => {
    setNewBillPayment(true);
  }

  const closePurchaseBillPay = () => {
    setNewBillPayment(false);
  }

  return (
    <>
      <div className="purchase-bill-pay-main-container">
        <div className="purchase-bill-pay-container-first-div">
          <div className="purchase-bill-pay-first-left">
            <span className="all-purchase-bill-pay-show">All Bill Payment()</span>
          </div>
          <div className="purchase-bill-pay-first-right">
            <button
              className="purchase-bill-pay-first-right-botton"
              onClick={handlNewBillPay}
            >
              New bill Payment
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-bill-pay-table-group">
          <table className="purchase-bill-pay-table">
            <thead>
              <tr className="purchase-bill-pay-thead-row">
                <th className="purchase-bill-pay-tab-th">Date</th>
                <th className="purchase-bill-pay-tab-th">Bill Payment No</th>
                <th className="purchase-bill-pay-tab-th">Contact/Account</th>
                <th className="purchase-bill-pay-tab-th">Paid throught Account</th>
                <th className="purchase-bill-pay-tab-th">Payment Mode</th>
                <th className="purchase-bill-pay-tab-th">Ref.No</th>
                <th className="purchase-bill-pay-tab-th">Amount</th>
                <th className="purchase-bill-pay-tab-th">Due Amount</th>
                <th className="purchase-bill-pay-tab-th">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="purchase-bill-pay-tab-td"></td>
                <td className="purchase-bill-pay-tab-td"></td>
                <td className="purchase-bill-pay-tab-td"></td>
                <td className="purchase-bill-pay-tab-td"></td>
                <td className="purchase-bill-pay-tab-td"></td>
                <td className="purchase-bill-pay-tab-td"></td>
                <td className="purchase-bill-pay-tab-td"></td>
                <td className="purchase-bill-pay-tab-td"></td>
                <td className="purchase-bill-pay-tab-td">
                  action
                  <FontAwesomeIcon icon={faCaretDown} className="purchase-bill-pay-table-body-td" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {openNewBillPayment && (
        <div className="new-purchase-bill-pay-popup-container">
          <NewBillPayment onClose={closePurchaseBillPay} />
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default BillPayment;
