// apiRequest.js
import axios from 'axios';
import { getToken, removeToken } from './components/SecureStorage/Token';
import ReactDOM from 'react-dom';
import React from 'react';
import './SubscriptionPopup.css'; 

const PopupWrapper = ({ errorData, onClose }) => {

  const handleClose = () => {
    onClose();
    window.location.href = '/dashboard'; // Add redirect to dashboard
  };
  
  return (
    <div className="subscription-popup-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <div className="subscription-popup-content" style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        width: '90%',
        position: 'relative'
      }}>
        <h2>Upgrade Your Subscription</h2>
        <p>{errorData.message}</p>
        <div className="subscription-details">
          <h3>Upgrade to Premium</h3>
          <ul>
            <li>Access to all features, including {errorData.feature}</li>
            <li>24/7 customer support</li>
            <li>Advanced analytics</li>
            <li>Unlimited users</li>
          </ul>
        </div>
        <button 
          className="sub-close-button" 
          onClick={handleClose}
          style={{
            padding: '8px 16px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const handleFeatureUnavailable = (errorData) => {
  
  try {
    const existingPopup = document.getElementById('feature-popup-container');
    if (existingPopup) {
      document.body.removeChild(existingPopup);
    }

    const popupContainer = document.createElement('div');
    popupContainer.id = 'feature-popup-container';
    document.body.appendChild(popupContainer);

    const closePopup = () => {
      try {
        ReactDOM.unmountComponentAtNode(popupContainer);
        document.body.removeChild(popupContainer);
      } catch (err) {
        console.error('Error closing popup:', err);
      }
    };

    ReactDOM.render(
      <PopupWrapper 
        errorData={errorData} 
        onClose={closePopup}
      />,
      popupContainer
    );
  } catch (err) {
    console.error('Error showing popup:', err);
  }
};

export const sendRequest = async (method, url, data, options = {}) => {
  const token = getToken();
  const branchId = localStorage.getItem('branchId');
  
  const config = {
    ...options,
    headers: {
      ...(options.headers || {}),
      Authorization: token ? `${token}` : ''
    },
    params: {
      ...(options.params || {}),
      branch_id: branchId,
      devise_type: 'web',
    },
  };

  try {
    const response = await axios({
      method,
      url,
      data,
      ...config,
    });
    return response;
  } catch (error) {
    if (error.response?.status === 403 && 
        error.response?.data?.error === 'Feature not available') {
      handleFeatureUnavailable(error.response.data);
    }
    
    if (error.response?.data?.errors?.[0] === 'Signature has expired' || 
        error.response?.data?.errors === 'Unauthorized user!') {
      removeToken();
      window.location.replace('/');
    }

    throw error.response || error.message;
  }
};