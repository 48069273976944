import React, { useState } from "react";
import "./CommonSupplierPopup.css";
import CommonCloseButton from "../Restaurant/CommonCloseButton/CommonCloseButton";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";

function CommonSupplierPopup({selectedSupplier, onClose}){
  const [isFirstName, setFirstName] = useState();
  const [isLastName, setLastName] = useState();
  const [isPhone, setPhone] = useState();
  const [isemail, setEmail] = useState();
  const [isSuppCompany, setSuppCompany] = useState();
  const [isComAddress, setComAddress] = useState();
  
  const handleSupplierSave = async () => {
    try {
      const formData = new FormData();
      formData.append("supplier[first_name]", isFirstName);
      formData.append("supplier[last_name]", isLastName);
      formData.append("supplier[phone_number]", isPhone);  
      formData.append("supplier[email]", isemail);
      formData.append("supplier[supp_company]", isSuppCompany);
      formData.append("supplier[com_address]", isComAddress);
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/suppliers`,
        formData
      );
      selectedSupplier(response?.data?.data);
      onClose();
    } catch (error) {
      console.log("supplier not created!")
    }
  };

  return(
    <>
      <div className="common-supplier-main-container">
        <div className="common-supplier-header-content">
          <span className="common-supplier-title">Crete supplier</span>
          <span className="common-supplier-close">
            <CommonCloseButton onClick={onClose}/>
          </span>
        </div>
        <div className="common-supplier-sub-container">
          <div className="common-supplier-form-grp">
            <div className="common-supplier-form-field-grp">
              <label className="common-supplier-label">First Name</label>
              <input 
                className="form-control common-supplier-label-field" 
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                value={isFirstName}
              />
            </div>
            <div className="common-supplier-form-field-grp">
              <label className="common-supplier-label">Last Name</label>
              <input 
                className="form-control common-supplier-label-field" 
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                value={isLastName}
              />
            </div>
            <div className="common-supplier-form-field-grp">
              <label className="common-supplier-label">Phone</label>
              <input 
                className="form-control common-supplier-label-field" 
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                value={isPhone}
              />
            </div>
            <div className="common-supplier-form-field-grp">
              <label className="common-supplier-label">Email</label>
              <input 
                className="form-control common-supplier-label-field" 
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={isemail}
              />
            </div>
            <div className="common-supplier-form-field-grp">
              <label className="common-supplier-label">Supplier Company</label>
              <input 
                className="form-control common-supplier-label-field" 
                type="text"
                onChange={(e) => setSuppCompany(e.target.value)}
                value={isSuppCompany}
              />
            </div>
            <div className="common-supplier-form-field-grp">
              <label className="common-supplier-label">Company Address</label>
              <input 
                className="form-control common-supplier-label-field" 
                type="text"
                onChange={(e) => setComAddress(e.target.value)}
                value={isComAddress}
              />
            </div>
          </div>
        </div>
        <div className="common-supplier-btn-grp">
          <button className="common-supplier-btn" onClick={handleSupplierSave}>Save</button>
          <button className="common-supplier-btn" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </>
  );
}

export default CommonSupplierPopup;
