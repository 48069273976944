import React, { useEffect, useState } from "react";
import "./NewPurchaseQuotation.css"
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import CommonSalePopupTable from "../../CompanySales/CommonSalePopupTable/CommonSalePopupTable";
import SaleCommonPopup from "../../CompanySales/SaleCommonPopupHeader/SaleCommonPopupHeader";
import CommonSupplierPopup from "../../Common/CommonSupplierPopup";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { current_user } from "../../../Authentication/AuthenticationContext";

function NewPurchaseQuotation({ onClose }) {

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const [isShowSupplierPopup, setShowSupplierPopup] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [isEstimateDate, setEstimateDate] = useState("");
  const [isValidTillDate, setValidTillDate] = useState("");
  const [isReference, setReference] = useState("");
  const [isOrderNo, setOrderNo] = useState("");
  const [isOrderDate, setOrderDate] = useState("");

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/suppliers?query=${term}`
        );
        const suppliers = response.data.data;
        if (suppliers.length > 0) {
          setFilteredSuppliers(suppliers);
          setShowDropdown(true);
          setShowAddSupplier(false);
        } else {
          setFilteredSuppliers([]);
          setShowDropdown(true);
          setShowAddSupplier(true);
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddSupplier(true);
      }
    } else {
      setShowDropdown(false);
      setShowAddSupplier(false);
    }
  };

  const handleSelectSupplier = (supplier) => {
    setSupplierId(supplier.id);
    setSearchTerm(supplier.first_name || supplier.last_name);
    setShowDropdown(false);
  };

  const handleCreateSupplier = () => {
    setShowSupplierPopup(true);
  }

  const handleAddSupplier = () => {
    setShowSupplierPopup(true);
    setShowDropdown(false);
    setShowAddSupplier(false);
  };

  const updatedSelectedItems = (items) => {
    setSelectedItems(items);
  }

  const handleTotalAmount = (amount) => {
    setTotalAmount(amount);
  }

  const handleSubTotal = (amount) => {
    setSubTotal(amount);
  }

  const handleGstValue = (amount) => {
    setGstAmount(amount);
  }

  const handleDiscountValue = (amount) => {
    setDiscount(amount);
  }

  const closeCommonSupplierPopup = () => {
    setShowSupplierPopup(false);
  }

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
    };
    fetchUser();
  }, []);

  const handleNewPurchaseQuotation = async () => {
    try {
      const uniqueItems = [];
      const itemIds = new Set();
      selectedItems.forEach(item => {
        if (!itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item?.item_id,
            quantity: item?.qty,
            item_total_discount: item?.discountAmount?.toFixed(2),
            item_total_tax: item?.tax?.toFixed(2),
            item_total_amount: item?.saleAmount?.toFixed(2),
            branch_id: branchId.id
          });
        }
      });

      const formData = {
        bill: {
          supplier_id: supplierId || 1,
          estimate_date: isEstimateDate,
          valid_date: isValidTillDate,
          reference_no: isReference,
          order_no: isOrderNo,
          order_date: isOrderDate,
          bill_items_attributes: uniqueItems,
          total_amount: totalAmount,
          sub_total: subTotal,
          total_gst: gstAmount,
          status: 0,
          total_discount: discount
        }
      }
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/transactions/entries?type=quotation`,
        formData
      );
      console.log("response", response);
      successNotification("Purchase quotation Created!");
      onClose();
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  }

  return (
    <>
      <div className="new-purchase-quote-main-container">
        <SaleCommonPopup onClose={onClose} onSave={handleNewPurchaseQuotation} />
        <div className="new-purchase-quote-sub-container">
          <div className="new-purchase-quote-first-div">
            <div className="new-purchase-quote-estimate-no-grp">
              <span className="new-purchase-quote-est-no-title">Purchase Quote No.</span>
              <span className="new-purchase-quote-est-no">PQ2</span>
            </div>
            <div className="new-purchase-quote-estimate-date-grp">
              <span className="new-purchase-quote-estdate-title">Purchase Quote Date</span>
              <span className="new-purchase-quote-estdate-input">
                <input className="form-control new-purchase-quote-first-div-input"
                  type="date"
                  onChange={(e) => setEstimateDate(e.target.value)}
                  value={isEstimateDate}
                />
              </span>
            </div>
            <div className="new-purchase-quote-tilldate-grp">
              <span className="new-purchase-quote-est-tilldate-title">Valid Till</span>
              <span className="new-purchase-quote-est-tilldate-input">
                <input className="form-control new-purchase-quote-first-div-input"
                  type="date"
                  onChange={(e) => setValidTillDate(e.target.value)}
                  value={isValidTillDate}
                />
              </span>
            </div>
            <div className="new-purchase-quote-reference-grp">
              <span className="new-purchase-quote-est-reference-title">Reference#</span>
              <span className="new-purchase-quote-est-reference-input">
                <input className="form-control new-purchase-quote-first-div-input"
                  type="text"
                  onChange={(e) => setReference(e.target.value)}
                  value={isReference}
                />
              </span>
            </div>
          </div>

          <div className="new-purchase-quote-second-div">
            <div className="new-purchase-quote-customer-div">
              <span className="new-purchase-quote-cust-title">Supplier</span>
              <span className="new-purchase-quote-cust-search">
                <input
                  className="form-control new-purchase-quote-cust-field"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search supplier..."
                />
                {showDropdown && (
                  <ul className="new-purchase-quote-customer-dropdown">
                    {filteredSuppliers.length > 0 ? (
                      filteredSuppliers.map((supplier, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectSupplier(supplier)}
                          className="new-purchase-quote-dropdown-item"
                        >
                          {supplier.first_name || supplier.last_name}
                        </li>
                      ))
                    ) : (
                      <li className="new-purchase-quote-cust-dropdown-item">No supplier found</li>
                    )}
                    {showAddSupplier && (
                      <li className="customer-dropdown-item" onClick={handleAddSupplier}>
                        <button className="btn btn-primary" onClick={handleCreateSupplier}>Add Supplier</button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
            </div>

            <div className="new-purchase-quote-buyer-order-grp">
              <span className="new-purchase-quote-buyer-order-title">Supplier Quotation No</span>
              <input className="form-control new-purchase-quote-buyer-order"
                type="number"
                onChange={(e) => setOrderNo(e.target.value)}
                value={isOrderNo}
              />
            </div>
            <div className="new-purchase-quote-buyer-order-date-grp">
              <span className="new-purchase-quote-buyer-order-date-title">Supplier Quotation Date</span>
              <input className="form-control new-purchase-quote-buyer-order-date" 
                type="date" 
                onChange={(e) => setOrderDate(e.target.value)}
                value={isOrderDate}
              />
            </div>
          </div>

          <div className="new-purchase-quote-table">
            <CommonSalePopupTable
              updatedSelectedItems={(items) => updatedSelectedItems(items)}
              totalAmountValue={(totalAmount) => handleTotalAmount(totalAmount)}
              subTotalValue={(subTotal) => handleSubTotal(subTotal)}
              gstValue={(gstValue) => handleGstValue(gstValue)}
              discountValue={(discount) => handleDiscountValue(discount)}
            />
          </div>
        </div>
      </div>

      {isShowSupplierPopup && (
        <div className="purchase-quote-supplier-popup">
          <CommonSupplierPopup onClose={closeCommonSupplierPopup} />
        </div>
      )}
    </>
  );
}

export default NewPurchaseQuotation;
