import React, { useEffect, useState } from "react";
import "./PurchaseQuotation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseQuotation from "./NewPurchaseQuotation";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import PaginationControls from "../../Common/PaginationControls";


function PurchaseQuotation() {
  const [isNewPurchaseQuote, setNewPurchaseQuote] = useState(false);
  const [purchaseQuotations, setPurchaseQuotations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const handlePurchaseQuote = () => {
    setNewPurchaseQuote(true);
  }

  const closeNewPurchasePopup = () => {
    setNewPurchaseQuote(false);
  };

  useEffect(() => {
    fetchPurchaseQuotations(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const fetchPurchaseQuotations = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=purchase_quotation&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setPurchaseQuotations(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching purchase quotations:", error);
    }
  };

  return (
    <>
      <div className="purchase-quote-main-container">
        <div className="purchase-quote-container-first-div">
          <div className="purchase-quote-first-left">
            <span className="all-purchase-quote-show">All Purchase Quotation ()</span>
          </div>
          <div className="purchase-quote-first-right">
            <button
              className="purchase-quote-first-right-botton"
              onClick={handlePurchaseQuote}
            >
              New quote
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-quotation-pagination-header">
          <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
        </div>

        <div className="purchase-quote-table-group">
          <table className="purchase-quote-table">
            <thead>
              <tr className="purchase-quote-thead-row">
                <th className="purchase-quote-tab-th">Date</th>
                <th className="purchase-quote-tab-th">Purchase Quote No</th>
                <th className="purchase-quote-tab-th">Contact</th>
                <th className="purchase-quote-tab-th">Supplier Quatation No</th>
                <th className="purchase-quote-tab-th">Supplier Quatation Date</th>
                <th className="purchase-quote-tab-th">Ref.No</th>
                <th className="purchase-quote-tab-th">Due Days</th>
                <th className="purchase-quote-tab-th">Status</th>
                <th className="purchase-quote-tab-th">Amount</th>
                <th className="purchase-quote-tab-th">Due Amount</th>
                <th className="purchase-quote-tab-th">Action</th>
              </tr>
            </thead>
            <tbody>
              {purchaseQuotations.map((row, index) => (
                <tr key={index}>
                  <td className="purchase-quote-tab-td">{row.date}</td>
                  <td className="purchase-quote-tab-td">{row.bill_number}</td>
                  <td className="purchase-quote-tab-td">{row.supplier.supplier_name}</td>
                  <td className="purchase-quote-tab-td">{row.supplier.supplier_order_no}</td>
                  <td className="purchase-quote-tab-td">{row.supplier.supplier_order_date}</td>
                  <td className="purchase-quote-tab-td">{row.reference_no}</td>
                  <td className="purchase-quote-tab-td">{row.due_days}</td>
                  <td className="purchase-quote-tab-td">{row.status}</td>
                  <td className="purchase-quote-tab-td">{row.total_amount}</td>
                  <td className="purchase-quote-tab-td">{row.due_amount}</td>
                  <td className="purchase-quote-tab-td">
                    <FontAwesomeIcon icon={faCaretDown} className="purchase-quote-table-body-td" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}
      </div>
      {purchaseQuotations.length === 0 && (
        <div className="purchase-quotation-no-data-message">
          No purchase quotations found.
        </div>
      )}

      {isNewPurchaseQuote && (
        <div className="new-purchase-quote-popup-container">
          <NewPurchaseQuotation onClose={closeNewPurchasePopup} />
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseQuotation;
