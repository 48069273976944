import React, { useState } from "react";
import "./PurchaseOrder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseOrder from "./NewPurchaseOrder";


function PurchaseOrder() {
  const [openNewPurchaseOrder, setNewPurchaseOrder] = useState(false);

  const handlePurchaseOrder = () => {
    setNewPurchaseOrder(true);
  }

  const closeNewPurchaseOrderPopup = () => {
    setNewPurchaseOrder(false);
  }

  return (
    <>
      <div className="purchase-order-main-container">
        <div className="purchase-order-container-first-div">
          <div className="purchase-order-first-left">
            <span className="all-purchase-order-show">All Purchase Order()</span>
          </div>
          <div className="purchase-order-first-right">
            <button
              className="purchase-order-first-right-botton"
              onClick={handlePurchaseOrder}
            >
              New order
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-order-table-group">
          <table className="purchase-order-table">
            <thead>
              <tr className="purchase-order-thead-row">
                <th className="purchase-order-tab-th">Date</th>
                <th className="purchase-order-tab-th">Purchase Order No</th>
                <th className="purchase-order-tab-th">Contact</th>
                <th className="purchase-order-tab-th">Supplier Order No</th>
                <th className="purchase-order-tab-th">Supplier Order Date</th>
                <th className="purchase-order-tab-th">Ref.No</th>
                <th className="purchase-order-tab-th">Due Days</th>
                <th className="purchase-order-tab-th">Status</th>
                <th className="purchase-order-tab-th">Amount</th>
                <th className="purchase-order-tab-th">Due Amount</th>
                <th className="purchase-order-tab-th">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td"></td>
                <td className="purchase-order-tab-td">
                  action
                  <FontAwesomeIcon icon={faCaretDown} className="purchase-order-table-body-td" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {openNewPurchaseOrder && (
        <div className="new-purchase-order-popup-container">
          <NewPurchaseOrder onClose={closeNewPurchaseOrderPopup} />
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseOrder;
