import React, { useEffect, useState, useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { removeToken } from "./SecureStorage/Token";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BackButton from "../BackButton";
import { useTranslation } from "react-i18next";

import "../Header/Header.css";
import { current_user } from "../Authentication/AuthenticationContext";
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "../Url/constants";
import { Unauthorized } from "../components/Unauthorized";

const dropdown = [
  { text: "Profile", link: "/profile" },
  { text: "Sign Out", link: "/" },
];

const Header = ({ handleLogout, setIsDashboardClicked }) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [branchData, setBranchData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(
    localStorage.getItem("branchId") || ""
  );
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setUser(currentUser);
      setLoading(false);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/Billing" || path.includes("/billing")) {
      setActiveTab(t("Billings"));
    } else if (path === "/Reports" || path.includes("/reports")) {
      setActiveTab(t("Reports"));
    } else if (path === "/stocks") {
      setActiveTab(t("Stocks"));
    } else if (path === "/restaurants" || path.includes("/restaurants")) {
      setActiveTab(t("Restaurant"));
    } else if (path === "/sales") {
      setActiveTab(t("Sales"));
    } else if (
      path === "/inventory" ||
      path.includes("/Inventory") || // Keep in mind that path comparison is case-sensitive
      path === "/item" ||
      path.includes("/item-details") ||
      path.includes("/item-update")
    ) {
      setActiveTab(t("Inventory"));
    } else if (path === "/customers" || path === "/show-customer") {
      setActiveTab(t("Customers"));
    } else if (
      path === "/Suppliers" ||
      path === "/add-supplier" ||
      path.includes("/show-supplier") ||
      path.includes("/update-supplier")
    ) {
      setActiveTab(t("Suppliers"));
    } else if (
      (path === "/Staff" || path.includes("/staff"),
      path === "/add-staff" ||
        path.includes("/show-staff") ||
        path.includes("/update-staff") ||
        path.includes("/staff"))
    ) {
      setActiveTab(t("Staff"));
    } else if (path === "/cash-memo") {
      setActiveTab(t("Cash-Memo"));
    } else {
      setActiveTab("");
    }
  }, [location, t]);

  const changeLanguage = (lng) => {
    setLoading(true);
    i18n.changeLanguage(lng, () => {
      setLoading(false);
    });
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    if (selectedLanguage) {
      changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      (user.user_detail.role.name === "admin" ||
        user.user_detail.role.name === "owner")
    ) {
      getBranchList();
    }
  }, [user]);

  const getBranchList = async () => {
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/onboarding/companies/${user.company.id}/branches`
    );
    if (response.status === 200) {
      setBranchData(response.data.data);
    }
  };

  const handleBranchChange = (e) => {
    const branchId = e.target.value;
    setSelectedBranch(branchId);
    if (branchId) {
      localStorage.setItem("branchId", branchId);
    } else {
      localStorage.removeItem("branchId");
    }
    window.location.reload();
  };

  if (loading) {
    return <div>{t("Loading...")}</div>;
  }

  const handleSignOut = () => {
    handleLogout();
    navigate("/");
    setActiveTab("");
    localStorage.clear();
  };

  const routesWithoutBackButton = [
    "/dashboard",
    "/Billing",
    "/Reports",
    "/addPurchase",
    "/addSale",
    "/inventory",
    "/customers",
    "/Suppliers",
    "/Staff",
    "/reports",
    "/create-company",
    "/roles-permissions",
    "/staff",
    "/suppliers",
  ];

  const shouldShowBackButton = !routesWithoutBackButton.includes(
    location.pathname
  );
  const hasFeature = (featureName) => {
    return (
      user &&
      user.features?.some(
        (feature) => feature?.name === featureName && feature?.enable
      )
    );
  };

  return (
    <div className="header-container">
      {setIsDashboardClicked && (
        <div className="search-input-container">
          <input type="text" placeholder={t("Search Transactions")} />{" "}
          {/* Translate placeholder */}
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>
      )}
      <div className="header-back-button-margine">
        {shouldShowBackButton && <BackButton />}
      </div>

      {user &&
        (user.user_detail.role.name === "admin" ||
          user.user_detail.role.name === "owner") && (
          <div>
            <select
              className="form-control selected-branch-name"
              value={selectedBranch}
              onChange={handleBranchChange}
            >
              <option value="">{t("All Branch")}</option>
              {branchData.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.branch_name}
                </option>
              ))}
            </select>
          </div>
        )}
      {user &&
        (user.user_detail.role.name === "admin" ||
          user.user_detail.role.name === "owner" ||
          user.user_detail.role.name === "manager") && (
          <div className="button-contents">
            {(() => {
              const allowedRoles = ["manager"];
              const isAdminOrOwner =
                user.user_detail.role.name === "admin" ||
                user.user_detail.role.name === "owner";

              // Check if the "Billing" tab should be rendered
              const shouldRenderBilling =
                isAdminOrOwner ||
                (allowedRoles.includes(user.user_detail.role.name) &&
                  user?.user_detail?.role?.permissions?.some(
                    (permission) =>
                      permission.name === "can manage pos" &&
                      permission.role_permissions?.some((rp) => rp.is_enable)
                  ));

              // Check if the "Restaurant" tab should be rendered
              const shouldRenderRestaurant =
                isAdminOrOwner ||
                (allowedRoles.includes(user.user_detail.role.name) &&
                  user?.user_detail?.role?.permissions?.some(
                    (permission) =>
                      permission.name === "can manage pos" &&
                      permission.role_permissions?.some((rp) => rp.is_enable)
                  ));

              return (
                <div className="button-contents">
                  {hasFeature("Restaurant") && shouldRenderRestaurant && (
                    <div
                      className={`report-content ${
                        activeTab === t("Restaurant") ? "active" : ""
                      }`}
                    >
                      <Link
                        title={t("Restaurant")}
                        className="header-link"
                        to="/restaurants"
                      >
                        {t("Restaurant")}
                      </Link>
                    </div>
                  )}

                  {hasFeature("Billing") && shouldRenderBilling && (
                    <div
                      className={`report-content ${
                        activeTab === t("Billings") ? "active" : ""
                      }`}
                    >
                      <Link
                        title={t("Billing")}
                        className="header-link"
                        to="/Billing"
                      >
                        {t("Billing")}
                      </Link>
                    </div>
                  )}
                </div>
              );
            })()}

            {(() => {
              const allowedRoles = ["manager"];
              const isAdminOrOwner =
                user.user_detail.role.name === "admin" ||
                user.user_detail.role.name === "owner";

              // Check if the "Reports" tab should be rendered
              const shouldRenderReports =
                isAdminOrOwner ||
                (allowedRoles.includes(user.user_detail.role.name) &&
                  user?.user_detail?.role?.permissions?.some(
                    (permission) =>
                      permission.name === "can view report" &&
                      permission.role_permissions?.some((rp) => rp.is_enable)
                  ));

              // Only render the "Reports" tab if the user has permission
              return (
                shouldRenderReports && (
                  <div
                    className={`report-content ${
                      activeTab === t("Reports") ? "active" : ""
                    }`}
                  >
                    <Link
                      title={t("Reports")}
                      className="header-link"
                      to="/Reports"
                    >
                      {t("Reports")}
                    </Link>
                  </div>
                )
              );
            })()}

            <div
              className={`report-content ${
                activeTab === t("Stocks") ? "active" : ""
              }`}
            >
              <Link className="header-link" to="/stocks">
                {t("Stocks")}
              </Link>
            </div>
            <div
              className={`report-content ${
                activeTab === t("Sales") ? "active" : ""
              }`}
            >
            </div>

            {(() => {
              const allowedRoles = ["manager"];
              const isAdminOrOwner =
                user.user_detail.role.name === "admin" ||
                user.user_detail.role.name === "owner";

              // Check if the "Inventory" tab should be rendered
              const shouldRenderInventory =
                isAdminOrOwner ||
                (allowedRoles.includes(user.user_detail.role.name) &&
                  user?.user_detail?.role?.permissions?.some(
                    (permission) =>
                      permission.name === "can manage inventory" &&
                      permission.role_permissions?.some((rp) => rp.is_enable)
                  ));
              // Only render the "Inventory" tab if the user has permission
              return (
                shouldRenderInventory && (
                  <div
                    className={`report-content ${
                      activeTab === t("Inventory") ? "active" : ""
                    }`}
                  >
                    <Link className="header-link" to="/inventory">
                      {t("Inventory")}
                    </Link>
                  </div>
                )
              );
            })()}
            {hasFeature("Normal_Sale") && (
            <div className={`report-content ${activeTab === t("Sales") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/sales"
              >
                {t("Sales")}
              </Link>
            </div>
            )}
            {hasFeature("Cash_memo") && (
            <div className={`report-content ${activeTab === t("Cash-Memo") ? "active" : ""}`}>
              <Link
                className="header-link"
                to="/cash-memo"
              >
                {t("CashMemo")}
              </Link>
            </div>
            )}

            <div
              className={`report-content ${
                activeTab === t("Customers") ? "active" : ""
              }`}
            >
              <Link className="header-link" to="/customers">
                {t("Customers")}
              </Link>
            </div>
            <div
              className={`report-content ${
                activeTab === t("Suppliers") ? "active" : ""
              }`}
            >
              <Link className="header-link" to="/Suppliers">
                {t("Suppliers")}
              </Link>
            </div>
            {(() => {
              const allowedRoles = ["manager"];
              const isAdminOrOwner =
                user.user_detail.role.name === "admin" ||
                user.user_detail.role.name === "owner";

              // Check if the "Staff" tab should be rendered
              const shouldRenderStaff =
                isAdminOrOwner ||
                user?.user_detail?.role?.permissions?.some(
                  (permission) =>
                    allowedRoles.includes(user.user_detail.role.name) &&
                    permission.role_permissions?.some((rp) => rp.is_enable) &&
                    permission.name === "can manage staff"
                );

              return (
                <div
                  className={`report-content ${
                    activeTab === t("Staff") ? "active" : ""
                  }`}
                >
                  {shouldRenderStaff && (
                    <div
                      className={`report-content ${
                        activeTab === t("Staff") ? "active" : ""
                      }`}
                    >
                      <Link className="header-link" to="/Staff">
                        {t("Staff")}
                      </Link>
                    </div>
                  )}
                </div>
              );
            })()}
          </div>
        )}

      {user && ["staff", "cashier"].includes(user?.user_detail?.role?.name) && (
        <div className="button-contents">
          {(() => {
            // Check permissions for Restaurant tab
            const restaurantPermission =
              user.user_detail.role.name === "admin" ||
              user.user_detail.role.name === "owner" ||
              user?.user_detail?.role?.permissions?.some(
                (permission) =>
                  permission.name === "can manage pos" &&
                  permission.role_permissions?.some((rp) => rp.is_enable)
              );

            return (
              restaurantPermission && hasFeature("Restaurant") && (
                <div
                  className={`report-content ${
                    activeTab === t("Restaurant") ? "active" : ""
                  }`}
                >
                  <Link className="header-link" to="/restaurants">
                    {t("Restaurant")}
                  </Link>
                </div>
              )
            );
          })()}

          {(() => {
            // Check permissions for Billings tab
            const billingPermission =
              user.user_detail.role.name === "admin" ||
              user.user_detail.role.name === "owner" ||
              user?.user_detail?.role?.permissions?.some(
                (permission) =>
                  permission.name === "can manage pos" &&
                  permission.role_permissions?.some((rp) => rp.is_enable)
              );

            return (
              billingPermission && hasFeature("Billing") && (
                <div
                  className={`report-content ${
                    activeTab === t("Billings") ? "active" : ""
                  }`}
                >
                  <Link className="header-link" to="/Billing">
                    {t("Billings")}
                  </Link>
                </div>
              )
            );
          })()}

          {(() => {
            // Check permissions for Reports tab
            const reportsPermission =
              user.user_detail.role.name === "admin" ||
              user.user_detail.role.name === "owner" ||
              user?.user_detail?.role?.permissions?.some(
                (permission) =>
                  permission.name === "can view report" &&
                  permission.role_permissions?.some((rp) => rp.is_enable)
              );

            return (
              reportsPermission && (
                <div
                  className={`report-content ${
                    activeTab === t("Reports") ? "active" : ""
                  }`}
                >
                  <Link className="header-link" to="/Reports">
                    {t("Reports")}
                  </Link>
                </div>
              )
            );
          })()}
        </div>
      )}

      <div className="dropdown-box" ref={dropdownRef}>
        <BsThreeDotsVertical onClick={() => setOpen(!open)} />
        {open && (
          <div className="dropdown-menus">
            <ul>
              {dropdown.map((menu, index) => (
                <Link
                  to={menu.link}
                  onClick={menu.text === "Sign Out" ? handleSignOut : () => {}}
                >
                  <li key={index}>
                    {t(menu.text)} {/* Translate menu items */}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* <div className="language-switcher">
        <button onClick={() => changeLanguage('en')}>English</button>
        <button onClick={() => changeLanguage('hi')}>Hindi</button>
      </div> */}
    </div>
  );
};

export default Header;
