import React from "react";
import "./SaleCommonPopupHeader.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";

function SaleCommonPopup({ onClose, onSave, isUpdated, isView = false, onUpdate, BtnDisable}) {
  return (
    <>
      <div className="sale-common-popup-main-container">
        <div className="sale-common-popup-left-part">
          <span>New Estimate</span>
          <div className="sale-common-location-group">
            <span className="sale-common-location-symbol">
              <FontAwesomeIcon icon={faHouseChimney} />
            </span>
            <span className="sale-common-location-name">
              Indore
            </span>
          </div>
        </div>
        <div className="sale-common-popup-right-part">
          {/* <div className="sale-common-popup-btn-group">
            <button className="sale-common-header-button">Print</button>
          </div> */}
          {!isView && (
            <>
              {isUpdated ? (
                <div>
                  <button className="sale-common-header-button" 
                    onClick={onUpdate}
                    disabled={BtnDisable}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div>
                  <button className="sale-common-header-button" 
                    onClick={onSave}
                    disabled={BtnDisable} 
                  >
                      Save
                    </button>
                </div>
              )}
            </>
          )}
          <div>
            <button className="sale-common-header-button" onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SaleCommonPopup;
