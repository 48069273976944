import React, { useState, useEffect } from "react";
import { getToken } from "../SecureStorage/Token";
import "./Cashmemo.css";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import { FaTrash } from "react-icons/fa";
import UnitOptions from "../Purchases/UnitOption";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import GetCustomer from "../GetCustomer";
import Copyrights from "../Copyrights";
import { useTranslation } from "react-i18next";
import CustomTable from "../Common/CustomTable";
import EntriesPerPageDropdown from "../Common/EntriesPerPageDropdown";
import PaginationControls from "../Common/PaginationControls";
import { Link, useLocation } from "react-router-dom";
import Search from "../Search/Search";
import CustomMemoTable from "./CustomMemoTable";

const SuccessNotification = (message) => {
  NotificationManager.success(message);
};

const CashMemo = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([
    {
      item_name: "",
      hsn: "",
      quantity: "",
      price: "",
      unit_id: "",
      tax_id: "",
      tax_amount: "",
      total_with_tax: 0
    }
  ]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalTax, setTotalTax] = useState(0);
  const [billSettings, setBillSettings] = useState([]);
  const [tableData, setTableDAta] = useState([]);
  const [subAmount, setSubAmount] = useState(0);
  const [itemSelected, setItemSelected] = useState(items.map(() => false));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isTransactionsView, setIsTransactionsView] = useState(false);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [isTransactionModelOpen, setIsTransactionModelOpen] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [units, setUnits] = useState([]);
  const [taxType, setTaxType] = useState("Individual");
  const [individualTaxes, setIndividualTaxes] = useState([]);
  const [groupTaxes, setGroupTaxes] = useState([]);

  const token = getToken();
  const unit = UnitOptions({ token });
  const location = useLocation();

  const tab = location.state?.tab;
  const [activeView, setActiveView] = useState(
    tab === "transactions" ? "transactions" : "addPurchase"
  );

  const handleTaxTypeChange = async (event) => {
    const selectedTaxType = event.target.value;
    setTaxType(selectedTaxType);
    try {
      if (selectedTaxType === "Individual") {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/taxes/taxes`,
          null,
          token
        );
        setIndividualTaxes(response?.data.data || []);
      } else if (selectedTaxType === "Group") {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/taxes/tax_groups`,
          null,
          token
        );
        setGroupTaxes(response?.data.data || []);
      }
    } catch (error) {
      ErrorNotification("Error fetching taxes:", error);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/units`,
        null,
        token
      );
      setUnits(response.data.data);
    } catch (error) {
      ErrorNotification("Error fetching units:", error);
      setUnits([]);
    }
  };

  const createMemo = async (memoData) => {
    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/memos/memos`,
        memoData,
        token
      );

      if (response.status === 200 || response.status === 201) {
        SuccessNotification("Cash memo created successfully");
        return true;
      }
      return false;
    } catch (error) {
      ErrorNotification(error.message || "Failed to create cash memo");
      return false;
    }
  };

  const handleButtonClick = (activeView) => {
    setActiveView(activeView);
    if (activeView === "transactions") {
      setIsTransactionsView(true);
    } else {
      setIsTransactionsView(false);
    }
  };

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      // await fetchTransactions(pageNumber);
    }
  };
  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query.trim());
      setCurrentPage(1);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  const openTransactionModal = (details) => {
    if (details?.length > 0) {
      setTransactionDetails(details);
      setIsTransactionModelOpen(true);
    } else {
      ErrorNotification("No Transactions Available");
    }
  };

  const closeTransactionModel = () => {
    setIsTransactionModelOpen(false);
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setRecordPerPage(perPage);
    setCurrentPage(1);
  };

  const col_names = [
    t("S No."),
    t("Items"),
    t("Total Amount"),
    t("Total Tax"),
    t("Created At"),
    t("Action"),
  ];

  const handleAddItem = () => {
    setSelectedItem(null);
    setItems([
      ...items,
      {
        item_name: "",
        hsn: "",
        quantity: "",
        avail_quantity: "",
        item_code: "",
        tax_rate_id: "",
        amount: "",
        company_id: 1,
        unit: "",
        purchase_price: "",
        tax_amount: "",
        tax_type: "",
        tax_value: "",
      },
    ]);
    setItemSelected([...itemSelected, false]);
  };

  useEffect(() => {
    fetchUnits();
    handleTaxTypeChange({ target: { value: "Individual" } }); // Initial fetch of individual taxes
  }, []);

  useEffect(() => {
    if (activeView === "transactions") {
      fetchTransactions(currentPage);
    }
  }, [activeView]);

  const handlePrintMemo = (memo) => {
    // Calculate totals with correct tax
    const memoTotals = memo.cash_memo_items.reduce((acc, item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const price = parseFloat(item.price) || 0;
      const baseAmount = quantity * price;
      let taxAmount = 0;
  
      if (item.tax_id) {
        const tax = item.tax || individualTaxes.find(t => t.id === parseInt(item.tax_id)) || 
                   groupTaxes.find(t => t.id === parseInt(item.tax_id));
        
        if (tax) {
          if (tax.tax_type === 'percentage') {
            taxAmount = (baseAmount * tax.tax_value) / 100;
          } else {
            taxAmount = tax.tax_value;
          }
        }
      }
  
      return {
        baseAmount: acc.baseAmount + baseAmount,
        taxAmount: acc.taxAmount + taxAmount
      };
    }, { baseAmount: 0, taxAmount: 0 });
  
    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Cash Memo Print</title>
          <style>
            body {
              padding: 20px;
              font-family: Arial, sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
            }
            th, td {
              border: 1px solid #000;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            .memo-header {
              text-align: center;
              margin-bottom: 20px;
              border-bottom: 2px solid #000;
              padding-bottom: 10px;
            }
            .memo-details {
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
            }
            .totals {
              margin-top: 20px;
              text-align: right;
              border-top: 2px solid #000;
              padding-top: 10px;
            }
            @media print {
              body {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
              @page {
                size: A4;
                margin: 15mm;
              }
            }
          </style>
        </head>
        <body>
          <div class="memo-header">
            <h2>Cash Memo</h2>
          </div>
          <div class="memo-details">
            <div>
              <p><strong>Memo No:</strong> ${memo.memo_number}</p>
            </div>
            <div>
              <p><strong>Date:</strong> ${new Date(memo.created_at).toLocaleString()}</p>
            </div>
          </div>
          
          <table>
            <thead>
              <tr>
                <th style="width: 5%">No.</th>
                <th style="width: 25%">Item Name</th>
                <th style="width: 10%">HSN</th>
                <th style="width: 10%">Quantity</th>
                <th style="width: 15%">Price (₹)</th>
                <th style="width: 15%">Tax Amount (₹)</th>
                <th style="width: 20%">Total (₹)</th>
              </tr>
            </thead>
            <tbody>
              ${memo.cash_memo_items.map((item, index) => {
                const quantity = parseFloat(item.quantity) || 0;
                const price = parseFloat(item.price) || 0;
                const baseAmount = quantity * price;
                let taxAmount = 0;
  
                if (item.tax_id) {
                  const tax = item.tax || individualTaxes.find(t => t.id === parseInt(item.tax_id)) || 
                             groupTaxes.find(t => t.id === parseInt(item.tax_id));
                  
                  if (tax) {
                    if (tax.tax_type === 'percentage') {
                      taxAmount = (baseAmount * tax.tax_value) / 100;
                    } else {
                      taxAmount = tax.tax_value;
                    }
                  }
                }
  
                const totalAmount = baseAmount + taxAmount;
  
                return `
                  <tr>
                    <td>${index + 1}</td>
                    <td>${item.item_name}</td>
                    <td>${item.item_code || '-'}</td>
                    <td>${quantity}</td>
                    <td>${price.toFixed(2)}</td>
                    <td>${taxAmount.toFixed(2)}</td>
                    <td>${totalAmount.toFixed(2)}</td>
                  </tr>
                `;
              }).join('')}
            </tbody>
          </table>
  
          <div class="totals">
            <p><strong>Sub Total:</strong> ₹${memoTotals.baseAmount.toFixed(2)}</p>
            <p><strong>Total Tax:</strong> ₹${memoTotals.taxAmount.toFixed(2)}</p>
            <p><strong>Grand Total:</strong> ₹${(memoTotals.baseAmount + memoTotals.taxAmount).toFixed(2)}</p>
          </div>
        </body>
      </html>
    `;
  
    // Create a new window and print
    const printWindow = window.open('', '_blank');
    printWindow.document.write(printContent);
    printWindow.document.close();
  
    // Function to handle printing
    const doPrint = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
    };
  
    // If document is already loaded, print immediately
    if (printWindow.document.readyState === 'complete') {
      doPrint();
    } else {
      // Wait for document to load before printing
      printWindow.onload = doPrint;
    }
  };
  
  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;

    // Calculate amounts for the current item
    const quantity = parseFloat(newItems[index].quantity) || 0;
    const price = parseFloat(newItems[index].price) || 0;
    const baseAmount = quantity * price;

    // Calculate tax if applicable
    if (newItems[index].tax_id) {
      const selectedTax = taxType === "Individual"
        ? individualTaxes.find(tax => tax.id === parseInt(newItems[index].tax_id))
        : groupTaxes.find(tax => tax.id === parseInt(newItems[index].tax_id));

      if (selectedTax) {
        const taxAmount = selectedTax.tax_type === 'percentage'
          ? (baseAmount * selectedTax.tax_value) / 100
          : selectedTax.tax_value;

        newItems[index].tax_amount = taxAmount;
        newItems[index].total_with_tax = baseAmount + taxAmount;
      }
    } else {
      newItems[index].tax_amount = 0;
      newItems[index].total_with_tax = baseAmount;
    }

    setItems(newItems);

    // Update totals
    const newTotalAmount = newItems.reduce((sum, item) => sum + (item.total_with_tax || 0), 0);
    const newTotalTax = newItems.reduce((sum, item) => sum + (item.tax_amount || 0), 0);

    setTotalAmount(newTotalAmount);
    setTotalTax(newTotalTax);
  };

  const handleRemoveItem = (indexToRemove) => {
    setItems((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
    setItemSelected((prevItem) =>
      prevItem.filter((_, index) => index !== indexToRemove)
    );
    const totalAmount = items.reduce(
      (acc, item) => acc + (item.amount || 0),
      0
    );
    setTotalAmount(totalAmount);
  };

  const handleSave = async () => {
    const hasEmptyFields = items.some(
      item => !item.item_name || !item.quantity || !item.price || !item.unit_id
    );

    if (hasEmptyFields) {
      ErrorNotification("Please fill all required fields");
      return;
    }

    setIsButtonDisabled(true);

    const memoData = {
      memo: {
        cash_memo_items_attributes: items.map(item => ({
          item_name: item.item_name,
          item_code: item.hsn,
          quantity: parseFloat(item.quantity),
          price: parseFloat(item.price),
          unit_id: parseInt(item.unit_id),
          tax_id: item.tax_id ? parseInt(item.tax_id) : null,
          tax_amount: item.tax_amount,
          total_with_tax: item.total_with_tax
        }))
      }
    };

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/memos/memos`,
        memoData,
        token
      );

      if (response.status === 200 || response.status === 201) {
        SuccessNotification("Cash memo created successfully");
        setItems([{
          item_name: "",
          hsn: "",
          quantity: "",
          price: "",
          unit_id: "",
          tax_id: "",
          tax_amount: 0,
          total_with_tax: 0
        }]);
        setTotalAmount(0);
        setTotalTax(0);
        setActiveView("transactions");
        await fetchTransactions(1);
      }
    } catch (error) {
      ErrorNotification(error.message || "Failed to create cash memo");
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const calculateTotalAmount = (items) => {
    return items.reduce((sum, item) => {
      const baseAmount = item.quantity * item.price;
      const tax = item.tax_amount || 0;
      return sum + baseAmount + tax;
    }, 0).toFixed(2);
  };

  const calculateTotalTax = (items) => {
    return items.reduce((sum, item) => {
      return sum + (item.tax_amount || 0);
    }, 0).toFixed(2);
  };

  const fetchTransactions = async (page = 1) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/memos/memos/transactions?page=${page}&per_page=${recordPerPage}`,
      );
      if (response.status === 200) {
        const transformedData = response.data.memos.map((memo, index) => {
          const memoTotals = memo.cash_memo_items.reduce((acc, item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const price = parseFloat(item.price) || 0;
            const baseAmount = quantity * price;
            let taxAmount = 0;
  
            if (item.tax_id) {
              const tax = item.tax || individualTaxes.find(t => t.id === parseInt(item.tax_id)) || 
                         groupTaxes.find(t => t.id === parseInt(item.tax_id));
              
              if (tax) {
                if (tax.tax_type === 'percentage') {
                  taxAmount = (baseAmount * tax.tax_value) / 100;
                } else {
                  taxAmount = tax.tax_value;
                }
              }
            }
  
            return {
              baseAmount: acc.baseAmount + baseAmount,
              taxAmount: acc.taxAmount + taxAmount
            };
          }, { baseAmount: 0, taxAmount: 0 });
  
          // Format items list
          const itemsList = memo.cash_memo_items.map(item => 
            `${item.item_name} (HSN: ${item.item_code || 'N/A'})`
          ).join(', ');
  
          return {
            "S No.": (page - 1) * recordPerPage + index + 1,
            "Memo No.": memo.memo_number,
            "Items": itemsList,
            "Total Amount": `₹ ${(memoTotals.baseAmount + memoTotals.taxAmount).toFixed(2)}`,
            "Total Tax": `₹ ${memoTotals.taxAmount.toFixed(2)}`,
            "Created At": new Date(memo.created_at).toLocaleDateString(),
            "Action": {
              type: 'button',
              label: 'Print Memo',
              onClick: () => handlePrintMemo(memo)
            },  
            // "Print": {
            //   type: 'button',
            //   label: 'Print',
            //   onClick: () => handlePrintMemo(memo)
            // }
          };
        });
  
        setTableDAta(transformedData);
        setTotalPages(response.data.total_pages);
        setTotalRecords(response.data.total_records);
      }
    } catch (error) {
      ErrorNotification(error.message || "Failed to fetch transactions");
    }
  };

  // const handleDownloadPDF = async (memoId) => {
  //   try {
  //     const response = await fetch(`${BASE_URL}/v1/memos/memos/${memoId}/download_pdf`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     }); 
  
  //     if (response.ok) {
  //       // Get the filename from the Content-Disposition header if available
  //       const contentDisposition = response.headers.get('Content-Disposition');
  //       let filename = `memo_${memoId}.pdf`;
  //       if (contentDisposition) {
  //         const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  //         if (filenameMatch && filenameMatch[1]) {
  //           filename = filenameMatch[1].replace(/['"]/g, '');
  //         }
  //       }
  
  //       // Convert the response to blob
  //       const blob = await response.blob();
        
  //       // Create a temporary URL for the blob
  //       const url = window.URL.createObjectURL(blob);
        
  //       // Create a temporary link element
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.download = filename;
        
  //       // Append link to body, click it, and remove it
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
        
  //       // Clean up the temporary URL
  //       window.URL.revokeObjectURL(url);
  //     } else {
  //       throw new Error('Failed to download PDF');
  //     }
  //   } catch (error) {
  //     ErrorNotification(error.message || 'Failed to download PDF');
  //   }
  // };


  return (
    <>
      <div className="content">
        <div className="cash-memo-page">
          <div className="d-flex gap-2">
            <button
              className={`bg_purchase_button p-4 rounded-3 ${activeView === "addPurchase" ? "active_purchase_button" : ""
                }`}
              onClick={() => handleButtonClick("addPurchase")}
            >
              {t("Cash-Memo")}
            </button>
            <button
              className={`bg_purchase_button p-4 rounded-3 ${activeView === "transactions" ? "active_purchase_button" : ""
                }`}
              onClick={() => handleButtonClick("transactions")}
            >
              {t("Transactions")}
            </button>
          </div>
          {activeView === "addPurchase" ? (
            <div className="billed-items-list">
              <div className="input-wrap">
                <GetCustomer />
              </div>
              <table>
                <thead className="table-thead">
                  <tr className="table-tr">
                    <th className="table-th sno">{t("S No.")}</th>
                    <th className="table-th">{t("Item Name")}</th>
                    <th className="table-th">{t("HSN No.")}</th>
                    <th className="table-th">{t("Quantity")}</th>
                    <th className="table-th">{t("Unit")}</th>
                    <th className="table-th">{t("Price/Unit")} (₹)</th>
                    <th className="table-th">{t("Tax")}</th>
                    <th className="table-th">{t("Amount")} (₹)</th>
                  </tr>
                </thead>
                <tbody className="t-body">
                  {items.map((item, index) => (
                    <tr key={index} className="table-row">
                      <td>{index + 1}</td>
                      <td className="table_td_border">
                        <input
                          type="text"
                          className="input-select form-control"
                          value={item.item_name}
                          onChange={(e) =>
                            handleItemChange(index, "item_name", e.target.value)
                          }
                        />
                      </td>
                      <td className="number-input-wrapper table-td">
                        <input
                          type=" text"
                          value={item.hsn}
                          name="hsn"
                          className="qty_input input-select form-control"
                          onChange={(e) =>
                            handleItemChange(index, "hsn", e.target.value)
                          }
                        />
                      </td>
                      <td className="number-input-wrapper table-td">
                        <input
                          type="number"
                          value={item.quantity}
                          className="qty_input input-select form-control"
                          onChange={(e) => handleItemChange(index, "quantity", e.target.value)}
                          min="0"
                        />
                      </td>
                      <td className="table-td">
                        <div className="unit-select-wrapper">
                          <select
                            className="form-control"
                            value={item.unit_id}
                            onChange={(e) => handleItemChange(index, "unit_id", e.target.value)}
                          >
                            <option value="">{t("Unit")}</option>
                            {units.map((unit) => (
                              <option key={unit.id} value={unit.id}>
                                {unit.unit_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>

                      <td className="table-td">
                        <input
                          type="number"
                          value={item.price}
                          className="form-control"
                          onChange={(e) => handleItemChange(index, "price", e.target.value)}
                          min="0"
                        />
                      </td>
                      <td className="table-td">
                        <div>
                          <select
                            className="form-control mb-2"
                            value={taxType}
                            onChange={handleTaxTypeChange}
                          >
                            <option value="Individual">{t("Individual Tax")}</option>
                            <option value="Group">{t("Tax Group")}</option>
                          </select>
                          <select
                            className="form-control"
                            value={item.tax_id || ""}
                            onChange={(e) => handleItemChange(index, "tax_id", e.target.value)}
                          >
                            <option value="">{t("Select Tax")}</option>
                            {taxType === "Individual"
                              ? individualTaxes.map((tax) => (
                                <option key={tax.id} value={tax.id}>
                                  {tax.title} ({tax.tax_value}%)
                                </option>
                              ))
                              : groupTaxes.map((taxGroup) => (
                                <option key={taxGroup.id} value={taxGroup.id}>
                                  {taxGroup.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="amount_trash">
                          <div className="amount">
                            <input
                              className="input-select form-control"
                              type="number"
                              value={item.total_with_tax || 0}
                              readOnly
                            />
                          </div>
                          <FaTrash
                            className="trash-icon"
                            onClick={() => handleRemoveItem(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="total-section">
                <span onClick={handleAddItem} className="add-btn">
                  + {t("Add Item")}
                </span>
                <span className="cal-total-sale">
                  {t("Subtotal")}: ₹ {(totalAmount - totalTax).toFixed(2)} |{" "}
                  {t("Tax")}: ₹ {totalTax.toFixed(2)} |{" "}
                  {t("Total")}: ₹ {totalAmount.toFixed(2)}
                </span>
              </div>
              <button className="save-btn" onClick={handleSave} disabled={isButtonDisabled}>
                {t("Save")}
              </button>
            </div>
          ) : (
            <div className="bd-example sp-table sp-table-header">
              {activeView === "transactions" && (
                <div className="transactions-list">
                  {/* <PurchaseReportForm t={t} onFilterChange={handleFilterChange} /> */}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col">
                        <EntriesPerPageDropdown
                          recordPerPage={recordPerPage}
                          handleRecordPerPageChange={handleRecordPerPageChange}
                        />
                      </div>
                      <div
                        className="col-md-2 align-content-center"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                      >
                        <Search placeholder={"Search Transactions ..."} />
                      </div>
                    </div>
                  </div>
                  <div className="bd-example sp-table sp-table-header">
                    <div className="report-table-data">
                      <CustomMemoTable col_names={col_names} data={tableData} />
                    </div>
                  </div>

                  <div>
                    {totalRecords >= recordPerPage && (
                      <div className="report-pagination-info">
                        <div className="report-pagination-container">
                          <PaginationControls
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalRecords={totalRecords}
                            setCurrentPage={setCurrentPage}
                            paginate={paginate}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="report-table-data">
                {/* <CustomTable col_names={col_names} data={tableData} /> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <Copyrights />
    </>
  );
};

export default CashMemo;
