import React, { useState } from "react";
import "./PurchaseDebitNote.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseDebitNote from "./NewPurchaseDebitNote";

function PurchaseDebitNote() {
  const[openNewPurchaseDebit, setNewPurchaseDebit] = useState();

  const handleNewDebitNote = () => {
    setNewPurchaseDebit(true);
  }

  const closeNewPurchaseDebit = () => {
    setNewPurchaseDebit(false);
  }
 
  return (
    <>
      <div className="purchase-debit-note-main-container">
        <div className="purchase-debit-note-container-first-div">
          <div className="purchase-debit-note-first-left">
            <span className="all-purchase-debit-note-show">All debit Note()</span>
          </div>
          <div className="purchase-debit-note-first-right">
            <button
              className="purchase-debit-note-first-right-botton"
              onClick={handleNewDebitNote}
            >
              New Debit Note
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-debit-note-table-group">
          <table className="purchase-debit-note-table">
            <thead>
              <tr className="purchase-debit-note-thead-row">
                <th className="purchase-debit-note-tab-th">Date</th>
                <th className="purchase-debit-note-tab-th">Debit Note No</th>
                <th className="purchase-debit-note-tab-th">Contact</th>
                <th className="purchase-debit-note-tab-th">Supplier Debit Note No</th>
                <th className="purchase-debit-note-tab-th">Supplier Debit Note Date</th>
                <th className="purchase-debit-note-tab-th">Ref.No</th>
                <th className="purchase-debit-note-tab-th">Due Days</th>
                <th className="purchase-debit-note-tab-th">Status</th>
                <th className="purchase-debit-note-tab-th">Amount</th>
                <th className="purchase-debit-note-tab-th">Due Amount</th>
                <th className="purchase-debit-note-tab-th">Action</th>              
              </tr>
            </thead>
            <tbody>
                <tr>
                  <td className="purchase-debit-note-tab-td"></td>
                  <td className="purchase-debit-note-tab-td"></td>
                  <td className="purchase-debit-note-tab-td"></td>
                  <td className="purchase-debit-note-tab-td"></td>
                  <td className="purchase-debit-note-tab-td"></td>
                  <td className="purchase-debit-note-tab-td"></td>       
                  <td className="purchase-debit-note-tab-td"></td>
                  <td className="purchase-debit-note-tab-td"></td>
                  <td className="purchase-debit-note-tab-td"></td>
                  <td className="purchase-debit-note-tab-td"></td>
                    <td className="purchase-debit-note-tab-td">
                      <FontAwesomeIcon icon={faCaretDown} className="purchase-debit-note-table-body-td"/>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>

      {openNewPurchaseDebit && (
        <div className="new-purchase-debit-note-popup-container">
          <NewPurchaseDebitNote onClose={closeNewPurchaseDebit}/>
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseDebitNote;
