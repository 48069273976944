import React, { useState } from "react";
import "./CommonToggleButton.css"

function CommonToggleButton({onToggleChange}) {
  const [isChecked, setIsChecked] = useState(true);

  const handleToggleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onToggleChange(newValue);
  };
  return (
    <>
      <div className="common-tax-wise-toggle-wrapper">
        <label className="common-tax-wise-toggle-container">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleToggleChange}
          />
          <span className="common-tax-wise-toggle-slider"></span>
        </label>
        <span className="common-tax-wise-toggle-label">
          Item Wise Tax
        </span>
      </div>
    </>
  );
}
export default CommonToggleButton;
